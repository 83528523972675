
@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  font-family: 'Secular One', sans-serif;
  direction: rtl;
}


.form_input {
  @apply rounded-full w-3/4 md:w-2/4 h-10 drop-shadow-md placeholder:pr-2;
}

.form_btn {
  @apply w-1/3 h-12 bg-brand-yellow rounded-full drop-shadow-xl;
}